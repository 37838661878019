<template>
  <app-base-table
    :tableOptions="tableOptions" 
    @re-fetch-data="fetchData"
    @re-fetch-paginated-data="fetchData($event)"
    :displaySettings="false"
  >
  </app-base-table>
</template>

<script>
import { mapActions , mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters({
      tableOptions: "buffet/getTableOptions" 
    }),
  },
  methods: {
    ...mapActions({
      fetchDepositCardRecord: "buffet/fetchDepositCardRecord",
    }),
    fetchData(page) {
      this.fetchDepositCardRecord({
        page,
      });
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>

<style>
</style>