<template>
  <div>
    <v-card class="px-3 mr-15 mt-10" elevation="0" v-if="!registerStatus">
      <h2 class="mb-5">
        {{ $t('class') }} : 
        <span style="font-weight: 100">{{ student.grade.name }}</span>
      </h2>
      <h2 class="mb-5">
        {{ $t('educitional class') }} :
        <span style="font-weight: 100">{{ student.class.name }}</span>
      </h2>
      <h2 class="mb-5">
        {{ $t('room') }} : 
        <span style="font-weight: 100">{{ student.room }}</span>
      </h2>
      <h2 class="mb-5">
        {{ $t('floor') }} : 
        <span style="font-weight: 100">{{ student.floor }}</span>
      </h2>
    </v-card>
    <v-card class="mr-15 mt-10" elevation="0" v-else>
      <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    student: {
      type: Object,
      default: () => {
        return {};
      },
    },
    registerStatus: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style>
</style>