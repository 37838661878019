<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-icon v-on="on" v-bind="attrs" color="info" class="mt-3"
          >mdi-pencil</v-icon
        >
      </template>
      <v-card>
        <v-card-title class="justify-center">
          <h1>{{ $t('edit') }}</h1>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col md="12">
              <v-autocomplete
                dense
                outlined
                hide-details
                v-model="newAbsent.type"
                :items="absentsTypes"
                :label="$t('Absent Type')"
                item-value="value"
                item-text="name"
              ></v-autocomplete>
            </v-col>
            <v-col md="12">
              <v-text-field
                :label="$t('hr.vacation.note')"
                v-model="newAbsent.note"
                dense
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="12">
              <input
                style="
                  width: 272.22px;
                  height: 40p;
                  border: 1px solid #999;
                  border-radius: 4px;
                  padding: 9px;
                  text-align: center;
                "
                type="date"
                v-model="newAbsent.date"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="justify-center">
          <v-btn
            class="white--text"
            color="green"
            depressed
            @click="submit"
            :loading="submitLoading"
            >{{ $t('edit') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      absentsTypes: [
        {
          name: "justified",
          value: "justified",
        },
        {
          name: "unjustified",
          value: "unjustified",
        },
      ],
      newAbsent: {
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        note: "",
        registration_record_id: null,
        type: "justified",
        // method: "_put",
      },
      dialog: false,
      dateDialog: false,
      submitLoading: false,
    };
  },
  props: {
    recordData: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  computed: {
    ...mapGetters({
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },
  methods: {
    async submit() {
      try {
        this.newAbsent.registration_record_id = this.getStudentRegId;
        this.submitLoading = true;
        await axios.put(
          `/absent/registration-record/${this.recordData.id}`,
          this.newAbsent
        );
        this.$Notifications(
          this.$t('edit success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.dialog = false;
        this.$emit("absentSubmitted");
      } catch (err) {
      } finally {
        this.submitLoading = false;
      }
    },
  },
  created() {
    this.newAbsent.note = this.recordData.note;
    this.newAbsent.type = this.recordData.type;
    this.newAbsent.date = this.recordData.date;
    console.log("edit absent", this.recordData);
  },
};
</script>

<style>
</style>