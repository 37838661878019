<template>
  <v-dialog v-model="dialog" width="800">
    <template v-slot:activator="{ on, attrs }">
      <v-icon v-on="on" v-bind="attrs" color="info"> mdi-pencil </v-icon>
    </template>
    <v-card>
      <v-card-title class="justify-center">
        <h1>{{ $t('edit') }}</h1>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col md="6">
            <v-autocomplete outlined dense :label="$t('note type')" :items="typeItems" v-model="note.type"
              item-text="name" item-value="value"></v-autocomplete>
          </v-col>
          <v-col md="6">
            <input style="
                width: 272.22px;
                height: 40p;
                border: 1px solid #999;
                border-radius: 4px;
                padding: 9px;
                text-align: center;
              " type="date" v-model="note.date" />
          </v-col>
          <v-col md="6">
            <v-text-field outlined dense :label="$t('note address')" v-model="note.title"></v-text-field>
          </v-col>
          <v-col md="12">
            <quill-editor v-model="note.note" ref="myQuillEditor" :options="quillOptions">
            </quill-editor>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn color="green" depressed class="white--text" @click="submitNote" :loading="btnLoading">{{ $t('edit')
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "axios";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      quillOptions: {
        placeholder: "أدخل الملاحظة هنا...",
        direction: "rtl",
      },
      btnLoading: false,
      dialog: false,
      dateDialog: false,
      typeItems: [
      {
          name: this.$t("medical"),
          value: "medical",
        },
        {
          name: this.$t("educational"),
          value: "educational",
        },
        {
          name: this.$t("psychological"),
          value: "psychological",
        },
      ],
      note: {
        title: "",
        type: {
          name: "",
          value: "",
        },
        note: "",
        date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        registration_record_id: null,
      },
    };
  },
  props: {
    recordData: Object,
  },
  computed: {
    ...mapGetters({
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },
  methods: {
    async submitNote() {
      try {
        this.btnLoading = true;
        console.log("type1", this.note.type);
        this.note.registration_record_id = this.getStudentRegId;
        await axios.put(`/note/${this.recordData.id}`, {
          title: this.note.title,
          type: this.note.type.value,
          note: this.note.note,
          date: this.note.date,
          registration_record_id: this.note.registration_record_id,
        });
        this.$Notifications(
          this.$t('edit success'),
          { rtl: true, timeout: 2000 },
          "success"
        );
        this.$emit("noteEdited");
        this.dialog = false;
        this.note.registration_record_id = this.getStudentRegId;
      } catch (err) {
        console.log("err", err);
      } finally {
        this.btnLoading = false;
      }
    },
  },
  created() {
    this.note.title = this.recordData.title;
    this.note.type.value = this.recordData.type;
    if (this.recordData.type == "medical") {
      this.note.type.name = "medical";
    } else if (this.recordData.type == "educational ") {
      this.note.type.name = "educational";
    } else {
      this.note.type.name = "psychological";
    }
    console.log("note fam", this.note);
    this.note.note = this.recordData.note;
    this.note.date = this.recordData.date;
  },
};
</script>

<style>
.fr-second-toolbar {
  display: none;
}
</style>