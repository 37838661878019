<template>
  <div>
    <v-card>
      <v-card-title>
        <v-container>
          <v-row>
            <v-col
              md="3"
              v-for="(photo, key, index) of pictures"
              :key="index"
              class="d-flex flex-column justify-center align-center"
            >
              <h2 class="mb-3">{{ $t(photo.text) }}</h2>
              <v-avatar size="150" tile>
                <v-img
                  style="cursor: pointer"
                  :src="photo.value"
                  v-if="photo.value"
                  @click="openPhoto(photo.value)"
                ></v-img>
                <v-icon v-else size="75"> mdi-alert-octagram </v-icon>
              </v-avatar>
            </v-col>
          </v-row>
        </v-container>
      </v-card-title>
    </v-card>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    pictures: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
};
</script>

<style></style>
