<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="green"
          class="white--text ml-3"
          rounded
          v-on="on"
          v-bind="attrs"
        >
          {{ $t("Student registration") }}
        </v-btn>
      </template>
      <v-card>
        <v-card-title class="d-flex justify-center card-title__heading">
          <h1>تسجيل الطالب</h1></v-card-title
        >
        <v-card-text>
          <v-autocomplete
            color="#757575"
            item-text="name"
            item-value="id"
            :loading="gradesLoading"
            :label="$t('class')"
            v-model="gradeId"
            :items="grades"
            @change="fetchEduClasses"
            no-filter
          ></v-autocomplete>
          <v-autocomplete
            color="#757575"
            :loading="eduClassesLoading"
            item-text="name"
            item-value="id"
            :label="$t('educitional class')"
            :disabled="!gradeId"
            v-model="educationalClassId"
            :items="eduClasses"
            no-filter
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            color="green"
            class="white--text"
            @click="acceptStudent"
            :loading="acceptBtnLoading"
            >تخزين</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Notifications from "../../../../helpers/toast-notifications";
import axios from "axios";
export default {
  data() {
    return {
      gradesLoading: false,
      eduClassesLoading: false,
      grades: [],
      eduClasses: [],

      dialog: false,
      gradeId: null,
      educationalClassId: null,
      acceptBtnLoading: false,
    };
  },
  props: ["studentId"],
  methods: {
    async acceptStudent() {
      try {
        this.acceptBtnLoading = true;
        await axios.post("registration-record", {
          student_id: this.studentId,
          edu_class_id: this.educationalClassId,
        });
        this.dialog = false;
        Notifications(
          "تمت العملية بنجاح",
          { timeout: 2000, rtl: true },
          "success"
        );
        this.$emit("studentAccepted");
      } catch (err) {
      } finally {
        this.acceptBtnLoading = false;
      }
    },
    async fetchEduClasses() {
      try {
        this.eduClassesLoading = true;
        const eduClasses = await axios.get("edu-class", {
          params: {
            grade_id: this.gradeId,
          },
        });
        this.eduClasses = eduClasses.data.classes;
      } catch (err) {
      } finally {
        this.eduClassesLoading = false;
      }
    },
  },
  async created() {
    try {
      this.gradesLoading = true;
      const response = await axios.get("/grade", {
        params: {
          size: 100000,
        },
      });
      this.grades = response.data.grades;
    } catch (err) {
      console.log("err", err);
    } finally {
      this.gradesLoading = false;
    }
  },
};
</script>

<style></style>
