<template>
  <div>
    <div v-if="!registerStatus">
      <v-container>
        <v-row>
          <v-col md="12">
            <app-add-absents
              @absentSubmitted="fetchData(1)"
              v-if="returnAbility('absent:store')"
            ></app-add-absents>
          </v-col>
        </v-row>
      </v-container>
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        serverRoute="/absent"
        :flaggedItems="flaggedItemsData"
        :enableDelete="displayDeleteIcon"
      >
        <template slot="editFeature" slot-scope="{ row }">
          <app-edit-absent
            v-if="
              returnAbility('absent:update') && returnAbility('absent:show')
            "
            @absentSubmitted="fetchData(1)"
            :recordData="row"
          ></app-edit-absent>
        </template>
      </app-base-table>
    </div>
    <v-card class="mr-15 mt-10" elevation="0" v-else>
      <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import addAbsents from "./addAbsent.vue";
import editAbsent from "./editAbsent.vue";
export default {
  components: {
    appEditAbsent: editAbsent,
    appAddAbsents: addAbsents,
  },
  data() {
    return {
      flaggedItemsData: ["type"],
    };
  },
  props: {
    registerStatus: {
      type: Boolean,
      default: false,
    },
  },
   computed: {
    ...mapGetters({
      tableOptions: "registrationRecord/getTableOptions",
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },
  methods: {
    ...mapActions({
      fetchStudentAbsents: "registrationRecord/fetchAbsents",
    }),
    fetchData(page) {
      this.fetchStudentAbsents({
        page,
        registration_record_id:this.getStudentRegId
      });
    },
  },
  created() {
    if (this.returnAbility("absent:destroy")) {
      this.displayDeleteIcon = true;
    }
    this.fetchData(1);
  },
};
</script>

<style>
</style>