<template>
  <div>
    <div class="table__spinnerWrapper" v-if="loading">
      <app-spinner></app-spinner>
    </div>
    <v-container style="margin-bottom: 100px" v-else>
      <v-row>
        <v-col md="4">
          <div class="student-buffet__card" v-if="card">
            <div class="student-buffer__heading-wrapper">
              <p class="student-buffet__heading">{{ $t('balance') }}</p>
            </div>
            <div class="student-buffet__value">
              <h3>{{ balance.toLocaleString() }}</h3>
            </div>
          </div>
        </v-col>
        <v-col md="4">
          <div class="student-buffet__card" v-if="card">
            <div class="student-buffer__heading-wrapper">
              <p class="student-buffet__heading">{{ $t('card number') }}</p>
            </div>
            <div class="student-buffet__value">
              <h3>{{ serial }}</h3>
            </div>
          </div>
        </v-col>
        <v-col md="4">
          <div class="student-buffet__card" v-if="card">
            <div class="student-buffer__heading-wrapper">
              <p class="student-buffet__heading">{{ $t('Daily Limit') }}</p>
            </div>
            <div class="student-buffet__value">
              <v-text-field flat dense hide-details v-model="daily_limit" @keypress.enter="updateDailyLimit"
                :loading="updatingDailyLimit"></v-text-field>
            </div>
          </div>
        </v-col>
        <v-col md="12">
          <hr />
        </v-col>
        <v-col md="2" v-if="returnAbility('buffet-card:store')">
          <app-add-card @cardAdded="fetchInfo" :cardStatus="card"></app-add-card>
        </v-col>

        <v-col md="2" v-if="returnAbility('deposit-card:charge')">
          <app-pull-charge-balance :cardStatus="card" :text="$t('Charge')" type="charge" :cardId="cardId" :totalBalance="balance"
            @done="fetchInfo"></app-pull-charge-balance>
        </v-col>
        <v-col md="3" v-if="returnAbility('deposit-card:pull')">
          <app-pull-charge-balance :cardStatus="card" :text="$t('Pull')" type="pull" :cardId="cardId" :totalBalance="balance"
            @done="fetchInfo"></app-pull-charge-balance>
        </v-col>

        <v-col md="2" v-if="returnAbility('deposit-card:index')">
          <v-btn large class="white--text" color="green" rounded :disabled="!card"
            @click="$router.push(`/meal/buffetCardReport/${studentId}`)">{{ $t('Buffet Card Record') }}</v-btn>
        </v-col>

        <!-- <v-col md="2" v-if="returnAbility('deposit-card:index')">
          <v-btn large class="white--text" color="green" rounded :disabled="!card"
            @click="$emit('displayDepositRecord')">{{ $t('Deposit Record') }}</v-btn>
        </v-col>
        <v-col md="2" v-if="returnAbility('purchase-history:index')">
          <v-btn large class="white--text" color="green" rounded :disabled="!card"
            @click="$emit('displayPurchaseRecord')">{{ $t('Purchase Record') }}</v-btn>
        </v-col> -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import addCard from "./addCard.vue";
import pullChargeBalance from "./pullChargeBalance.vue";
import depositRecord from "./despositRecord.vue";
import purchaseRecord from "./purchaseRecord.vue";
import { mapMutations } from "vuex";
export default {
  components: {
    appPurchaseRecord: purchaseRecord,
    appDepositRecord: depositRecord,
    appPullChargeBalance: pullChargeBalance,
    appAddCard: addCard,
  },
  data() {
    return {
      studentId: this.$route.params.id,
      cardId: null,
      balance: null,
      daily_limit: null,
      serial: null,
      buffet_note: null,
      ordered_meals: null,
      card: true,
      loading: false,
      updatingDailyLimit: false,
    };
  },
  methods: {
    ...mapMutations({
      setCardId: "buffet/setCardId",
    }),
    async updateDailyLimit() {
      try {
        this.updatingDailyLimit = true;
        await axios.post(`/buffet-card/${this.cardId}`, {
          _method: "put",
          daily_limit: this.daily_limit,
        });
        this.$Notifications(
          "تم تعديل الحد اليومي",
          { rtl: true, timeout: 1000 },
          "success"
        );
      } catch (err) {
      } finally {
        this.updatingDailyLimit = false;
      }
    },
    async fetchInfo() {
      try {
        this.loading = true;
        const response = await axios.get(`/buffet-card/${this.studentId}`);
        console.log("buffet res", response);
        if (response.data.data.card == false) {
          this.card = false;
          return;
        }
        this.card = true;
        this.balance = response.data.data.balance;
        this.daily_limit = response.data.data.daily_limit;
        this.buffet_note = response.data.data.buffet_note;
        this.ordered_meals = response.data.data.ordered_meals;
        this.serial = response.data.data.serial;
        this.cardId = response.data.data.id;
        this.setCardId(response.data.data.id);
      } catch (err) {
        console.log("err", err);
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.fetchInfo();
  },
};
</script>

<style>
.student-buffet__card {
  border: 1px solid #757575;
  border-radius: 20px;
  width: 150px;
  height: 120px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.student-buffer__heading-wrapper {
  text-align: center;
  width: 100%;
}

.student-buffet__heading {
  font-size: 1.5rem;
  font-weight: bold;
  border-bottom: 1px solid #757575;
  width: 100%;
  margin: 0 !important;
  padding: 7px 0;
}

.student-buffet__value {
  margin: 10px 0;
}
</style>