<template>
    <div>
        <v-container class="py-10">
            <v-row>
                <v-col md="12">
                    <v-container id="regular-tables" fluid tag="section">
                        <v-simple-table :dark="$store.state.isDarkMode">
                            <thead class="table-heading" v-if="!tableOptions.loading">
                                <tr>
                                    <th v-for="(name, i) in tableOptions.tableHeaders" :key="i">
                                        {{ $t(name) }}
                                    </th>
                                </tr>
                            </thead>
                            <div class="table__spinnerWrapper" v-if="tableOptions.loading">
                                <app-spinner></app-spinner>
                            </div>
                            <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!tableOptions.loading">
                                <template v-for="data in tableOptions.tableData">
                                    <tr :key="data.uid" style=" background: var(--group-by-col)">
                                        <th colspan="8" style=" border: 0; font-size: 1rem">
                                            <span v-if="data.key"> {{ data.key }} </span>
                                        </th>
                                    </tr>
                                    <tr v-for="(item, idx) in data.value" :key="idx">
                                        <td>{{ item.date }}</td>
                                        <td>{{ item.variable_name }}</td>
                                        <td>{{ item.variable_type }}</td>
                                    </tr>
                                </template>
                            </v-fade-transition>
                        </v-simple-table>
                        <div class="d-flex justify-center mt-5">
                            <v-row>
                                <app-pagination v-if="(!tableOptions.loading)" :totalPages="tableOptions.tableMeta.total"
                                    :page="tableOptions.tableMeta.page"
                                    @PaginationValue="applyPagination($event)"></app-pagination>
                            </v-row>
                        </div>
                    </v-container>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>
<script>

import axios from "axios";
import spinner from "../../../../../../components/base/spinner.vue";
import pagination from "../../../../../../components/base/pagination.vue";

export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
    },
    data() {
        return {
            loading: false,
            filters: {
                student_id: this.$route.params.id,
                group_by: "student_name",
            },
            tableOptions: {
                tableData: [],
                tableHeaders: ["date", "Variable Name", "Variable Type"],
                accessTableData: [["date"], ["variable_name"], ["variable_type"]],
                loading: false,
                tableMeta: {
                    total: null,
                    page: null,
                },
            },
        };
    },
    methods: {
        applyPagination(paginationValue) {
            this.page = paginationValue;
            this.fetchData();
        },
        async fetchData(page) {
            this.tableOptions.loading = true;
            const filterTemp = {};
            Object.keys(this.filters).forEach((key) => {
                if (this.filters[key]) filterTemp[key] = this.filters[key];
            });
            let params = {
                size: 10,
                page: page,
                ...filterTemp,
            };
            const response = await axios.get("/student-variable/report", { params });
            if (response.status == 200) {
                console.log(response.data.data);

                this.tableOptions.tableData = response.data.data,
                    this.tableOptions.tableMeta.total = response.data.total;
                this.tableOptions.tableMeta.page = response.data.current_page;
            }
            this.tableOptions.loading = false;
        },
    },
    created() {

        this.fetchData(1);
    },
};
</script>

<style></style>
