<template>
    <div>
      <v-card class="mr-15 mt-10" elevation="0" v-if="!registerStatus">
        <!-- <v-col md="3">
            <v-btn
        color="green"
        dark
        >
            {{ isNew ? $t("Generate Password") : $t("Regenerat Password")  }}
        </v-btn>
        </v-col>
        
          <h2 class="mb-5">
            {{ $t('user name') }}:
            <span style="font-weight: 100">{{ student.grade.name }}</span>
          </h2>
          <h2 class="mb-5">
          {{ $t('Password') }}:
            <span style="font-weight: 100">{{ student.class.name }}</span>
          </h2>
         -->
         <h2 class="mb-5">
          {{ $t('user name') }}:
          <span  style="font-weight: 100">{{ parentdata.username }}</span>
        </h2>
        <h2 class="mb-5">
          {{ $t('phone number') }}:
          <span  style="font-weight: 100">{{ parentdata.phone }}</span>
        </h2>
        <h2 class="mb-5">
        {{ $t('Password') }}:
          <span style="font-weight: 100">{{ parentdata.password }}</span>
        </h2>


      </v-card>
      <v-card class="mr-15 mt-10" elevation="0" v-else>
        <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
      </v-card>
    </div>
  </template>
  
  <script>
import axios from 'axios';

  
  export default {
    data(){
        return{
          parentdata: null,
          Loading: false,
            isNew: true,
        };
    },
   async created() {

      console.log(this.student);
      try {
      this.Loading = true;
      const response = await axios.get(`/parent/reg_rec/${this.student.registration_record_id}`);
      this.parentdata = response.data.data;
    } catch (err) {
      console.log("err", err);
    } finally {
      this.Loading = false;
    }
    },
    props: {
      student: {
        type: Object,
        default: () => {
          return {};
        },
      },
      registerStatus: {
        type: Boolean,
        default: false,
      },
    },
  };
  </script>
  
  <style>
  </style>