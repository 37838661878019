<template>
  <div>
    <v-container class="py-10" v-if="!registerStatus">
      <v-row>
        <v-col
          md="12"
          class="d-flex justify-end pl-5"
          v-if="returnAbility('note:store')"
        >
          <app-add-note @noteSubmitted="fetchData(1)"></app-add-note>
        </v-col>
        <v-col md="12">
          <app-base-table
            :tableOptions="tableOptions"
            :flaggedItems="flaggedItemsData"
            @re-fetch-data="fetchData"
            @re-fetch-paginated-data="fetchData($event)"
            serverRoute="/note"
            :enableDelete="displayDeleteIcon"
          >
            <template slot="editFeature" slot-scope="{ row }">
              <app-edit-note
                v-if="
                  returnAbility('note:update') && returnAbility('note:show')
                "
                :recordData="row"
                @noteEdited="fetchData(1)"
              ></app-edit-note>
            </template>
          </app-base-table>
        </v-col>
      </v-row>
    </v-container>
    <v-card class="mr-15 mt-10" elevation="0" v-else>
      <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
    </v-card>
  </div>
</template>

<script>
import addNote from "./addNote.vue";
import editNote from "./editNote.vue";
import { mapGetters } from 'vuex'
export default {
  components: {
    appEditNote: editNote,
    appAddNote: addNote,
  },
  data() {
    return {
      flaggedItemsData: ["registration_record_id", "type", "name"],
    };
  },
  props: {
    registerStatus: {
      type: Boolean,
      default: false,
    },
    student: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
   computed: {
    ...mapGetters({
      tableOptions: "registrationRecord/getTableOptions",
    }),
  },
  methods: {
    fetchData(paginationValue) {
      this.$store.dispatch("registrationRecord/fetchStudentNotes", {
        studentRegistrationId: this.student.registration_record_id,
        paginationValue,
      });
    },
  },
  created() {
    if (this.returnAbility("note:destroy")) {
      this.displayDeleteIcon = true;
    }
    if (this.student.registration_record_id) {
      this.fetchData(1);
    }
  },
};
</script>

<style>
</style>