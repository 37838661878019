<template>
  <div>
    <div v-if="!registerStatus">
      <app-base-table
        :tableOptions="tableOptions"
        @re-fetch-data="fetchData"
        @re-fetch-paginated-data="fetchData($event)"
        :displaySettings="false"
      >
      </app-base-table>
    </div>
    <v-card class="mr-15 mt-10" elevation="0" v-else>
      <h2 class="mb-5"> {{ $t('The Student Is Not Registered In This Term') }} : </h2>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
        tableOptions: "registrationRecord/getTableOptions",
      getStudentRegId: "registrationRecord/getStudentRegistrationId",
    }),
  },
  props: {
    registerStatus: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      fetchTests: "registrationRecord/fetchTests",
    }),
    fetchData(page) {
      this.fetchTests({
        page,
        registration_record_id: this.getStudentRegId,
      });
    },
  },
  created() {
    this.fetchData(1);
  },
};
</script>

<style>
</style>