<template>
  <div>
    <v-container>
      <v-row>
        <v-col md="3">
          <v-autocomplete
            :items="products"
            item-value="id"
            item-text="name"
            v-model="product_id"
            label="البحث حسب اسم المادة"
            outlined
            dense
            :loading="productsLoading"
            clearable
          ></v-autocomplete>
        </v-col>
        <v-col md="3">
          <input
            style="
              width: 272.22px;
              height: 40p;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
            "
            type="date"
            v-model="from_date"
          />
        </v-col>
        <v-col md="3">
          <input
            style="
              width: 272.22px;
              height: 40p;
              border: 1px solid #999;
              border-radius: 4px;
              padding: 9px;
              text-align: center;
            "
            type="date"
            v-model="to_date"
          />
        </v-col>
        <v-col md="3" class="d-flex align-center">
          <v-icon @click="fetchData(1)">mdi-magnify</v-icon>
        </v-col>
      </v-row>
    </v-container>
    <app-base-table
    :tableOptions="tableOptions" 
    @re-fetch-data="fetchData"
    @re-fetch-paginated-data="fetchData($event)"
    :displaySettings="false"
  >
  </app-base-table>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import axios from "axios";
export default {
  data() {
    return {
      studentId: this.$route.params.id,
      products: [],
      productsLoading: false,
      product_id: null,
      from_date: null,
      to_date: null,
      fromDialog: false,
      toDialog: false,
    };
  },
  computed: {
    ...mapGetters({
      tableOptions: "buffet/getTableOptions" 
    }),
  },
  methods: {
    ...mapActions({
      fetchPurchaseRecord: "buffet/fetchPurchaseRecord",
    }),
    fetchData(page) {
      this.fetchPurchaseRecord({
        page,
        student_id: this.studentId,
        product_id: this.product_id,
        from_date: this.from_date,
        to_date: this.to_date,
      });
    },
  },
  async created() {
    try {
      this.productsLoading = true;
      const products = await axios.get("/product", {
        params: {
          all: true,
        },
      });
      this.products = products.data.data;
    } catch (err) {
    } finally {
      this.productsLoading = false;
    }
    this.fetchData(1);
  },
};
</script>

<style>
</style>